import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    TextField,
    Theme,
    Typography,
    RadioGroup,
    Radio,
    FormLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Paper,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RootStoreContext } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import agent from 'api/agent';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IUpdateableBusinessEvent } from 'models/businessEvent/updateableBusinessEvent';
import dayjs, { Dayjs } from 'dayjs';
import { FormControl } from '@mui/base';
import ReactQuill from 'react-quill';
import CreateNewCoverImage from 'pages/BusinessEventsManager/components/CreateNewCoverImage';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { IParticipation } from 'models/participation/participation';
import { Delete as DeleteIcon } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        marginTop: 15,
        fontSize: 30,
        marginRight: 10,
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
}));

const GeneralInfoEditor: React.FC = () => {
    const styles = useStyles();
    const { selectedBusinessEvent } = useContext(RootStoreContext).businessEventStore;
    const [startDate, setStartDate] = React.useState<Dayjs | null>(dayjs(selectedBusinessEvent.startDate));
    const [endDate, setEndDate] = React.useState<Dayjs | null>(dayjs(selectedBusinessEvent.endDate));
    const [isLoading, setIsLoading] = useState(false);
    const [htmlDescriptionFromQuill, setHtmlDescriptionFromQuill] = useState<string>(
        selectedBusinessEvent?.descriptionInHtml || '',
    );
    const [coverImage, setCoverImage] = useState<ImageUploadResult>({ imageUrl: selectedBusinessEvent.coverImageLink });
    const [isCoverImagesUploadLoading, setIsCoverImagesUploadLoading] = useState(false);
    const [currentParticipation, setCurrentParticipation] = useState<IParticipation>({
        price: 0,
        duration: 0,
    });
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const areDatesValid = (): boolean => {
        const start = new Date(startDate.toDate());
        const end = new Date(endDate.toDate());

        if (start?.getTime() >= end?.getTime()) {
            showErrorToast(`A kezdeti dátum a befejezéssel egyidőben vagy utána van!`);
            return false;
        }
        return true;
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ 'indent': '-1'}, { 'indent': '+1' }],
            [
                {
                    color: [
                        '#000000',
                        '#E60000',
                        '#FF9900',
                        '#FFFF00',
                        '#008A00',
                        '#0066CC',
                        '#9933FF',
                        '#FFFFFF',
                        '#FACCCC',
                        '#FFEBCC',
                        '#FFFFCC',
                        '#CCE8CC',
                        '#CCE0F5',
                        '#EBD6FF',
                        '#BBBBBB',
                        '#F06666',
                        '#FFC266',
                        '#FFFF66',
                        '#66B966',
                        '#66A3E0',
                        '#C285FF',
                        '#888888',
                        '#A10000',
                        '#B26B00',
                        '#B2B200',
                        '#006100',
                        '#0047B2',
                        '#6B24B2',
                        '#444444',
                        '#5C0000',
                        '#663D00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3D1466',
                        '#A3157B',
                    ],
                },
            ],
            // [{ 'color': [] }, { 'background': [] }],
            // ['link'],
        ],
        keyboard: { bindings: { tab: false, indent: false, outdent: false } },
    };

    const handleParticipationChange = (field: keyof IParticipation, value: string) => {
        setCurrentParticipation({
            ...currentParticipation,
            [field]: parseInt(value) || 0,
        });
    };

    const handleSaveParticipation = (values: any, setFieldValue: (field: string, value: any) => void) => {
        const updatedParticipations = [...(values.participations || [])];

        if (editingIndex !== null) {
            updatedParticipations[editingIndex] = currentParticipation;
        } else {
            updatedParticipations.push(currentParticipation);
        }

        setFieldValue('participations', updatedParticipations);
        setCurrentParticipation({ price: 0, duration: 0 });
        setEditingIndex(null);
    };

    const handleRemoveParticipation = (
        index: number,
        values: any,
        setFieldValue: (field: string, value: any) => void,
    ) => {
        const updatedParticipations = values.participations.filter((_: any, i: number) => i !== index);
        setFieldValue('participations', updatedParticipations);
    };

    const handleEdit = (participation: IParticipation, index: number) => {
        setCurrentParticipation({ ...participation });
        setEditingIndex(index);
    };

    const handleCancel = () => {
        setCurrentParticipation({ price: 0, duration: 0 });
        setEditingIndex(null);
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Formik
                    initialValues={{
                        ...selectedBusinessEvent,
                        isVotingEnabled: selectedBusinessEvent.isVotingEnabled ?? false,
                        participations: selectedBusinessEvent.participations || [],
                    }}
                    validationSchema={Yup.object().shape({
                        name: Yup.string().required('Töltsd ki ezt a mezőt!'),
                        location: Yup.string().required('Töltsd ki ezt a mezőt!'),
                        startDate: Yup.date().nullable().required('Töltsd ki ezt a mezőt!'),
                        endDate: Yup.date().nullable().required('Töltsd ki ezt a mezőt!'),
                        isVotingEnabled: Yup.boolean().required('Töltsd ki ezt a mezőt!'),
                        description: Yup.string().required('Töltsd ki ezt a mezőt!'),
                        participations: Yup.array().of(
                            Yup.object().shape({
                                price: Yup.number().min(0, 'Az ár nem lehet negatív'),
                                duration: Yup.number().min(0, 'Az időtartam nem lehet negatív'),
                            }),
                        ),
                    })}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                        if (htmlDescriptionFromQuill === '' || htmlDescriptionFromQuill === '<p><br></p>') {
                            showErrorToast('Add meg a leírást!');
                            setSubmitting(false);
                            return;
                        }

                        if (!areDatesValid()) {
                            setSubmitting(false);
                            return;
                        }
                        const generalInformations: IUpdateableBusinessEvent = {
                            id: selectedBusinessEvent.id,
                            name: values.name,
                            description: values.description,
                            descriptionInHtml: htmlDescriptionFromQuill,
                            coverImageLink: coverImage.imageUrl,
                            location: values.location,
                            startDate: startDate.toDate(),
                            endDate: endDate.toDate(),
                            areNomineesEnabled: values.areNomineesEnabled,
                            isVotingEnabled: values.isVotingEnabled,
                            participations: values.participations,
                        };

                        agent.BusinessEvent.update(generalInformations)
                            .then(() => {
                                showSuccessToast(`Az esemény (${values.name}) módosítások elmentve!`);
                            })
                            .catch(() => showErrorToast(`Az esemény mentése sikertelen!`))
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({
                        errors,
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        values,
                        isSubmitting,
                        setFieldValue,
                    }) => (
                        <>
                            {isSubmitting ? (
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <CircularProgress />
                                    <Typography component="div">
                                        <Box textAlign="center" m={1}>
                                            Betöltés...
                                        </Box>
                                    </Typography>
                                </Box>
                            ) : (
                                <form onSubmit={handleSubmit} noValidate>
                                    <Box display="flex" m={1}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Név"
                                            type="text"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            error={Boolean(touched?.name && errors?.name)}
                                            helperText={touched?.name && errors?.name}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Rövid leírás"
                                            type="text"
                                            name="description"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.description}
                                            error={Boolean(touched?.description && errors?.description)}
                                            helperText={touched?.description && errors?.description}
                                        />
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Leírás:
                                        </Typography>
                                    </Box>
                                    <Box display="flex" m={1}>
                                        <ReactQuill
                                            style={{
                                                width: '100%',
                                            }}
                                            theme="snow"
                                            value={htmlDescriptionFromQuill}
                                            onChange={setHtmlDescriptionFromQuill}
                                            modules={modules}
                                        />
                                    </Box>
                                    <Box display="flex" m={1} mt={7}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            label="Helszín"
                                            type="text"
                                            name="location"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.location}
                                            error={Boolean(touched?.location && errors?.location)}
                                            helperText={touched?.location && errors?.location}
                                        />
                                    </Box>

                                    <Box display="flex" m={1}>
                                        <DateTimePicker
                                            label="Kezdés ideje"
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                                setFieldValue('startDate', newValue || null);
                                            }}
                                            value={startDate}
                                            format="YYYY.MM.DD HH:mm"
                                            className="custom-datepicker"
                                            disablePast
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    name: 'startDate',
                                                    value: startDate,
                                                    placeholder: 'Válassza ki a kezdés dátumát',
                                                    helperText: touched?.startDate && errors?.startDate,
                                                    error: Boolean(errors?.startDate && touched?.startDate),
                                                },
                                            }}
                                            defaultValue={null}
                                        />
                                        <Box m={1}></Box>
                                        <DateTimePicker
                                            label="Zárás ideje"
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                                setFieldValue('endDate', newValue || null);
                                            }}
                                            value={endDate}
                                            format="YYYY.MM.DD HH:mm"
                                            className="custom-datepicker"
                                            disablePast
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    name: 'endDate',
                                                    value: endDate,
                                                    placeholder: 'Válassza ki a zárás dátumát',
                                                    helperText: touched?.endDate && errors?.endDate,
                                                    error: Boolean(errors?.endDate && touched?.endDate),
                                                },
                                            }}
                                            defaultValue={null}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="start" m={1}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.areNomineesEnabled}
                                                    onChange={(e) => {
                                                        setFieldValue('areNomineesEnabled', e.target.checked);
                                                    }}
                                                    color="success"
                                                />
                                            }
                                            label="A Jelöltek engedélyezése"
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="start" m={1}>
                                        <FormControl>
                                            <FormLabel component="legend">Esemény jellege:</FormLabel>
                                            <RadioGroup
                                                aria-label="gender"
                                                name="gender"
                                                value={values.isVotingEnabled.toString()}
                                                onChange={(event) => {
                                                    setFieldValue('isVotingEnabled', event.target.value === 'true');
                                                }}
                                            >
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio />}
                                                    label="Jelölt feltöltés"
                                                />
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio />}
                                                    label="Közönség szavazás"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    <Box display="flex" justifyContent="start" m={1}>
                                        <CreateNewCoverImage
                                            isLoading={isCoverImagesUploadLoading}
                                            setCoverImageToUpload={setCoverImage}
                                            coverImageToUpload={coverImage}
                                            setIsLoading={setIsCoverImagesUploadLoading}
                                        />
                                    </Box>

                                    <Box sx={{ mt: 4, mb: 2 }}>
                                        <Typography variant="h6" gutterBottom>
                                            Részvételi lehetőségek
                                        </Typography>
                                    </Box>

                                    <Box display="flex" gap={2} alignItems="center" m={1}>
                                        <TextField
                                            label="Ár"
                                            type="number"
                                            value={currentParticipation.price}
                                            onChange={(e) => handleParticipationChange('price', e.target.value)}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                            }}
                                        />
                                        <TextField
                                            label="Időtartam (nap)"
                                            type="number"
                                            value={currentParticipation.duration}
                                            onChange={(e) => handleParticipationChange('duration', e.target.value)}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                            }}
                                        />
                                        <Button
                                            variant="contained"
                                            onClick={() => handleSaveParticipation(values, setFieldValue)}
                                            disabled={
                                                currentParticipation.price === 0 || currentParticipation.duration === 0
                                            }
                                        >
                                            {editingIndex !== null ? 'Mentés' : 'Hozzáadás'}
                                        </Button>
                                        {editingIndex !== null && (
                                            <Button variant="outlined" onClick={handleCancel}>
                                                Mégse
                                            </Button>
                                        )}
                                    </Box>

                                    {values.participations && values.participations.length > 0 && (
                                        <TableContainer component={Paper} sx={{ m: 1 }}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Sorszám</TableCell>
                                                        <TableCell>Ár</TableCell>
                                                        <TableCell>Időtartam (nap)</TableCell>
                                                        <TableCell align="right">Műveletek</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {values.participations.map(
                                                        (participation: IParticipation, index: number) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{index + 1}</TableCell>
                                                                <TableCell>{participation.price} Ft</TableCell>
                                                                <TableCell>{participation.duration} nap</TableCell>
                                                                <TableCell align="right">
                                                                    <Button
                                                                        size="small"
                                                                        onClick={() => handleEdit(participation, index)}
                                                                        disabled={editingIndex !== null}
                                                                    >
                                                                        Szerkesztés
                                                                    </Button>
                                                                    <IconButton
                                                                        color="error"
                                                                        onClick={() =>
                                                                            handleRemoveParticipation(
                                                                                index,
                                                                                values,
                                                                                setFieldValue,
                                                                            )
                                                                        }
                                                                        size="small"
                                                                        disabled={editingIndex !== null}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </IconButton>
                                                                </TableCell>
                                                            </TableRow>
                                                        ),
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    )}

                                    <Box display="flex" justifyContent="center" mt={2} className={styles.wrapper}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            disabled={isSubmitting || isLoading}
                                            type="submit"
                                        >
                                            Mentés
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </>
                    )}
                </Formik>
            </LocalizationProvider>
        </>
    );
};

export default observer(GeneralInfoEditor);
