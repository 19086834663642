import React, { useEffect, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Container,
    Grid,
    Typography,
    Theme,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import Header from './components/Header';
import CustomPage from 'components/CustomPage';
import { useRootStore } from 'mobx/rootStore';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    detailItem: {
        padding: theme.spacing(1, 0),
        wordBreak: 'break-word',
    },
}));

const AttendeeDetails: React.FC = () => {
    const [searchParams] = useSearchParams();
    const attendeeId = searchParams.get('id');

    const [isLoading, setLoading] = useState(false);
    const { loadSelectedAttendee, selectedAttendee } = useRootStore().attendeeStore;
    const styles = useStyles();

    useEffect(() => {
        setLoading(true);
        loadSelectedAttendee(attendeeId).finally(() => {
            setLoading(false);
        });
    }, []);

    return (
        <>
            <CustomPage title="Attendee Details">
                <Container maxWidth={false}>
                    <Header />
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
                            <CircularProgress />
                        </Box>
                    ) : selectedAttendee ? (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Card sx={{ width: '100%' }}>
                                    <CardHeader title={selectedAttendee.name} />
                                    <Divider />
                                    <CardContent>
                                        <List>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText primary="Név:" secondary={selectedAttendee.name} />
                                            </ListItem>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText primary="Pozíció:" secondary={selectedAttendee.role} />
                                            </ListItem>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText primary="Cég:" secondary={selectedAttendee.companyName} />
                                            </ListItem>
                                            {/* {selectedAttendee.category?.length > 0 && (
                                                <ListItem className={styles.detailItem}>
                                                    <ListItemText
                                                        primary="Kategória:"
                                                        secondary={selectedAttendee?.category}
                                                    />
                                                </ListItem>
                                            )} */}
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText primary="E-mail:" secondary={selectedAttendee.email} />
                                            </ListItem>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText
                                                    primary="Telefonszám:"
                                                    secondary={selectedAttendee.phoneNumber}
                                                />
                                            </ListItem>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText
                                                    primary="Leírás/Megjegyzés:"
                                                    secondary={selectedAttendee.description || 'Nincs megjegyzés'}
                                                />
                                            </ListItem>
                                            <ListItem className={styles.detailItem}>
                                                <ListItemText
                                                    primary="Részvétel:"
                                                    secondary={
                                                        selectedAttendee.participation
                                                            ? `${selectedAttendee.participation.price} Ft, ${selectedAttendee.participation.duration} nap`
                                                            : 'Nem választott'
                                                    }
                                                />
                                            </ListItem>
                                            {/* {selectedAttendee.attachment && (
                                                <ListItem className={styles.detailItem}>
                                                    <ListItemText
                                                        primary="Csatolmány:"
                                                        secondary={
                                                            <a
                                                                href={selectedAttendee.attachment.url.toString()}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {selectedAttendee.attachment.title}
                                                            </a>
                                                        }
                                                    />
                                                </ListItem>
                                            )} */}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    ) : (
                        <Typography variant="h5" align="center">
                            A jelentkező betöltése sikertelen volt. Kérem próbálja újra
                        </Typography>
                    )}
                </Container>
            </CustomPage>
        </>
    );
};

export default observer(AttendeeDetails);
