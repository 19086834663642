import React, { useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Paper,
} from '@mui/material';
import agent from 'api/agent';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ImageUploadResult } from 'models/image/imageUploadResult';
import { FileUploadResult } from 'models/common/fileUploadResult';
import { ITermsAndConditions } from 'models/businessEvent/termsAndConditions';
import { showErrorToast, showSuccessToast } from 'utils/customToaster';
import { INewBusinessEvent } from 'models/businessEvent/newBusinessEvent';
import { DateTimePicker } from '@mui/x-date-pickers';
import CreateNewSponsorsGrid from './CreateNewSponsorsGrid';
import { ISponsor } from 'models/businessEvent/sponsor';
import CreateNewTnc from './CreateNewTnc';
import ReactQuill from 'react-quill';
import Typography from '@mui/material/Typography';
import CreateNewCoverImage from './CreateNewCoverImage';
import { IParticipation } from 'models/participation/participation';
import { Delete as DeleteIcon } from '@mui/icons-material';

const CreateNewBusinessEventDialog: React.FC<{
    setIsCreateModeEnabled: (isEnabled: boolean) => void;
    setReloadRequest: (isEnabled: boolean) => void;
    isCreateModeEnabled: boolean;
}> = ({ setIsCreateModeEnabled, setReloadRequest, isCreateModeEnabled }) => {
    const [isDialogLoading, setDialogLoading] = useState(false);

    const [sponsorImagesToUpload, setSponsorImagesToUpload] = useState<ImageUploadResult[]>([]);
    const [isUploadSponsorImageLoading, setIsUploadSponsorImageLoading] = useState(false);
    const [sponsors, setSponsors] = useState<ISponsor[]>([]);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [fileToUpload, setFileToUpload] = useState<FileUploadResult>(null);
    const [htmlDescriptionFromQuill, setHtmlDescriptionFromQuill] = useState<string>();
    const [coverImage, setCoverImage] = useState<ImageUploadResult>();
    const [isCoverImagesUploadLoading, setIsCoverImagesUploadLoading] = useState(false);

    const [currentParticipation, setCurrentParticipation] = useState<IParticipation>({
        price: 0,
        duration: 0,
    });
    const [editingIndex, setEditingIndex] = useState<number | null>(null);

    const clearData = () => {
        setSponsorImagesToUpload([]);
        setStartDate(null);
        setEndDate(null);
        setFileToUpload(null);
        setIsCreateModeEnabled(false);
        setSponsors([]);
        setHtmlDescriptionFromQuill(null);
        setCurrentParticipation({ price: 0, duration: 0 });
        setEditingIndex(null);
    };

    const getAllImages = (): string[] => {
        const sponsorImageUrls = sponsorImagesToUpload.map((imageUploadResult) => imageUploadResult.imageUrl);
        return sponsorImageUrls;
    };

    const deleteImages = async () => {
        await agent.Image.deleteImages(getAllImages());
    };

    const deleteTnc = async () => {
        await agent.File.deleteFile(fileToUpload.url);
    };

    const deleteUploadedFiles = () => {
        deleteImages();
        deleteTnc();
    };

    const onClose = () => {
        deleteUploadedFiles();
        clearData();
    };

    const areSponsorsValid = (): boolean => {
        if (sponsors.length > 0) {
            const emptyLogoLink = sponsors.find((sponsor) => sponsor.sponsorLink === '');
            if (emptyLogoLink !== undefined) {
                showErrorToast(`Kérem adjon meg egy linket minden sponsorhoz!`);
                return false;
            }
        }
        return true;
    };

    const areDatesValid = (): boolean => {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start?.getTime() >= end?.getTime()) {
            showErrorToast(`A kezdeti dátum a befejezéssel egyidőben vagy utána van!`);
            return false;
        }
        return true;
    };

    const createNewBusinessEvent = (
        newBusinessEvent: INewBusinessEvent,
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        agent.BusinessEvent.create(newBusinessEvent)
            .then(() => {
                showSuccessToast(`Az új esemény (${newBusinessEvent.name}) elmentve!`);
                setReloadRequest(true);
                clearData();
            })
            .catch(() => showErrorToast(`Az Esemény mentése sikertelen!`))
            .finally(() => {
                setSubmitting(false);
                setDialogLoading(false);
            });
    };

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ 'indent': '-1'}, { 'indent': '+1' }],
            [
                {
                    color: [
                        '#000000',
                        '#E60000',
                        '#FF9900',
                        '#FFFF00',
                        '#008A00',
                        '#0066CC',
                        '#9933FF',
                        '#FFFFFF',
                        '#FACCCC',
                        '#FFEBCC',
                        '#FFFFCC',
                        '#CCE8CC',
                        '#CCE0F5',
                        '#EBD6FF',
                        '#BBBBBB',
                        '#F06666',
                        '#FFC266',
                        '#FFFF66',
                        '#66B966',
                        '#66A3E0',
                        '#C285FF',
                        '#888888',
                        '#A10000',
                        '#B26B00',
                        '#B2B200',
                        '#006100',
                        '#0047B2',
                        '#6B24B2',
                        '#444444',
                        '#5C0000',
                        '#663D00',
                        '#666600',
                        '#003700',
                        '#002966',
                        '#3D1466',
                        '#A3157B',
                    ],
                },
            ],
            // [{ 'color': [] }, { 'background': [] }],
            // ['link'],
        ],
        keyboard: { bindings: { tab: false, indent: false, outdent: false } },
    };

    const handleParticipationChange = (field: keyof IParticipation, value: string) => {
        setCurrentParticipation({
            ...currentParticipation,
            [field]: parseInt(value) || 0,
        });
    };

    const handleSaveParticipation = (values: any, setFieldValue: (field: string, value: any) => void) => {
        const updatedParticipations = [...(values.participations || [])];

        if (editingIndex !== null) {
            updatedParticipations[editingIndex] = currentParticipation;
        } else {
            updatedParticipations.push(currentParticipation);
        }

        setFieldValue('participations', updatedParticipations);
        setCurrentParticipation({ price: 0, duration: 0 });
        setEditingIndex(null);
    };

    const handleRemoveParticipation = (
        index: number,
        values: any,
        setFieldValue: (field: string, value: any) => void,
    ) => {
        const updatedParticipations = values.participations.filter((_: any, i: number) => i !== index);
        setFieldValue('participations', updatedParticipations);
    };

    const handleEdit = (participation: IParticipation, index: number) => {
        setCurrentParticipation({ ...participation });
        setEditingIndex(index);
    };

    const handleCancel = () => {
        setCurrentParticipation({ price: 0, duration: 0 });
        setEditingIndex(null);
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Dialog open={isCreateModeEnabled} onClose={() => onClose()} fullWidth={true} fullScreen={true}>
                    <DialogTitle>ESEMÉNY LÉTREHOZÁSA</DialogTitle>
                    {isDialogLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" style={{ minHeight: '120px' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Formik
                            initialValues={{
                                name: '',
                                description: '',
                                location: '',
                                termsAndConditions: fileToUpload,
                                startDate: startDate,
                                endDate: endDate,
                                isVotingEnabled: false,
                                participations: [],
                            }}
                            validationSchema={Yup.object().shape({
                                name: Yup.string().required('Töltsd ki ezt a mezőt!'),
                                location: Yup.string().required('Töltsd ki ezt a mezőt!'),
                                startDate: Yup.date().nullable().required('Töltsd ki ezt a mezőt!'),
                                endDate: Yup.date().nullable().required('Töltsd ki ezt a mezőt!'),
                                description: Yup.string().required('Töltsd ki ezt a mezőt!'),
                                participations: Yup.array().of(
                                    Yup.object().shape({
                                        price: Yup.number().min(0, 'Az ár nem lehet negatív'),
                                        duration: Yup.number().min(0, 'Az időtartam nem lehet negatív'),
                                    }),
                                ),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                if (htmlDescriptionFromQuill === '' || htmlDescriptionFromQuill === '<p><br></p>') {
                                    showErrorToast('Add meg a leírást!');
                                    setSubmitting(false);
                                    return;
                                }

                                if (!areDatesValid()) {
                                    setSubmitting(false);
                                    return;
                                }

                                if (!areSponsorsValid()) {
                                    setSubmitting(false);
                                    return;
                                }

                                const termsAndConditions: ITermsAndConditions | null = fileToUpload
                                    ? {
                                          url: fileToUpload.url,
                                          title: fileToUpload.fileName,
                                      }
                                    : null;

                                const newBusinessEvent: INewBusinessEvent = {
                                    name: values.name,
                                    description: values.description,
                                    descriptionInHtml: htmlDescriptionFromQuill,
                                    coverImageLink: coverImage.imageUrl,
                                    location: values.location,
                                    termsAndConditions: termsAndConditions,
                                    startDate: startDate,
                                    endDate: endDate,
                                    isVotingEnabled: values.isVotingEnabled,
                                    sponsors: sponsors,
                                    participations: values.participations,
                                };

                                createNewBusinessEvent(newBusinessEvent, setSubmitting);
                            }}
                        >
                            {({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                touched,
                                values,
                                isSubmitting,
                                setFieldValue,
                            }) => (
                                <form onSubmit={handleSubmit} noValidate>
                                    <DialogContent>
                                        <Box display="flex" mb={1}>
                                            <TextField
                                                error={Boolean(touched?.name && errors?.name)}
                                                fullWidth
                                                variant="outlined"
                                                helperText={touched?.name && errors?.name}
                                                label="Név"
                                                name="name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.name}
                                            />
                                        </Box>
                                        <Box display="flex"></Box>
                                        <Box display="flex" mb={1}>
                                            <TextField
                                                error={Boolean(touched?.location && errors?.location)}
                                                fullWidth
                                                variant="outlined"
                                                helperText={touched?.location && errors?.location}
                                                label="Helyszín"
                                                name="location"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.location}
                                            />
                                        </Box>
                                        <Box m={1}></Box>
                                        <DateTimePicker
                                            label="Kezdés ideje"
                                            onChange={(newValue) => {
                                                setStartDate(newValue);
                                                setFieldValue('startDate', newValue || null);
                                            }}
                                            value={startDate}
                                            format="YYYY.MM.DD HH:mm"
                                            className="custom-datepicker"
                                            disablePast
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    name: 'startDate',
                                                    value: startDate,
                                                    placeholder: 'Válassza ki a kezdés dátumát',
                                                    helperText: touched?.startDate && errors?.startDate,
                                                    error: Boolean(errors?.startDate && touched?.startDate),
                                                },
                                            }}
                                            defaultValue={null}
                                        />
                                        <Box m={1}></Box>
                                        <DateTimePicker
                                            label="Zárás ideje"
                                            onChange={(newValue) => {
                                                setEndDate(newValue);
                                                setFieldValue('endDate', newValue || null);
                                            }}
                                            value={endDate}
                                            format="YYYY.MM.DD HH:mm"
                                            className="custom-datepicker"
                                            disablePast
                                            views={['year', 'month', 'day', 'hours', 'minutes']}
                                            ampm={false}
                                            slotProps={{
                                                textField: {
                                                    name: 'endDate',
                                                    value: endDate,
                                                    placeholder: 'Válassza ki a zárás dátumát',
                                                    helperText: touched?.endDate && errors?.endDate,
                                                    error: Boolean(errors?.endDate && touched?.endDate),
                                                },
                                            }}
                                            defaultValue={null}
                                        />
                                        {/* <Box display="flex" justifyContent="start" m={1}>
                                            <FormControl>
                                                <FormLabel component="legend">Esemény jellege:</FormLabel>
                                                <RadioGroup
                                                    aria-label="voting"
                                                    name="voting"
                                                    value={values.isVotingEnabled.toString()}
                                                    onChange={(event) => {
                                                        setFieldValue('isVotingEnabled', event.target.value === 'true');
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value="false"
                                                        control={<Radio />}
                                                        label="Jelölt feltöltés"
                                                    />
                                                    <FormControlLabel
                                                        value="true"
                                                        control={<Radio />}
                                                        label="Közönség szavazás"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box> */}
                                        <Box display="flex" mt={1}>
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                label="Rövid leírás"
                                                type="text"
                                                name="description"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.description}
                                                error={Boolean(touched?.description && errors?.description)}
                                                helperText={touched?.description && errors?.description}
                                            />
                                        </Box>
                                        <Box display="flex">
                                            <Typography variant="subtitle1" gutterBottom>
                                                Leírás:
                                            </Typography>
                                        </Box>
                                        <Box display="flex" mb={6}>
                                            <ReactQuill
                                                style={{
                                                    width: '100%',
                                                }}
                                                theme="snow"
                                                value={htmlDescriptionFromQuill}
                                                onChange={setHtmlDescriptionFromQuill}
                                                modules={modules}
                                            />
                                        </Box>

                                        <Box mt={2}>
                                            <Typography variant="h6" gutterBottom>
                                                Részvételi lehetőségek
                                            </Typography>
                                        </Box>

                                        <Box display="flex" gap={2} alignItems="center" mb={2}>
                                            <TextField
                                                label="Ár"
                                                type="number"
                                                value={currentParticipation.price}
                                                onChange={(e) => handleParticipationChange('price', e.target.value)}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                }}
                                            />
                                            <TextField
                                                label="Időtartam (nap)"
                                                type="number"
                                                value={currentParticipation.duration}
                                                onChange={(e) => handleParticipationChange('duration', e.target.value)}
                                                InputProps={{
                                                    inputProps: { min: 0 },
                                                }}
                                            />
                                            <Button
                                                variant="contained"
                                                onClick={() => handleSaveParticipation(values, setFieldValue)}
                                                disabled={
                                                    currentParticipation.price === 0 ||
                                                    currentParticipation.duration === 0
                                                }
                                            >
                                                {editingIndex !== null ? 'Mentés' : 'Hozzáadás'}
                                            </Button>
                                            {editingIndex !== null && (
                                                <Button variant="outlined" onClick={handleCancel}>
                                                    Mégse
                                                </Button>
                                            )}
                                        </Box>

                                        {values.participations && values.participations.length > 0 && (
                                            <TableContainer component={Paper} sx={{ mb: 2 }}>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Sorszám</TableCell>
                                                            <TableCell>Ár</TableCell>
                                                            <TableCell>Időtartam (nap)</TableCell>
                                                            <TableCell align="right">Műveletek</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {values.participations.map(
                                                            (participation: IParticipation, index: number) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>{participation.price} Ft</TableCell>
                                                                    <TableCell>{participation.duration} nap</TableCell>
                                                                    <TableCell align="right">
                                                                        <Button
                                                                            size="small"
                                                                            onClick={() =>
                                                                                handleEdit(participation, index)
                                                                            }
                                                                            disabled={editingIndex !== null}
                                                                        >
                                                                            Szerkesztés
                                                                        </Button>
                                                                        <IconButton
                                                                            color="error"
                                                                            onClick={() =>
                                                                                handleRemoveParticipation(
                                                                                    index,
                                                                                    values,
                                                                                    setFieldValue,
                                                                                )
                                                                            }
                                                                            size="small"
                                                                            disabled={editingIndex !== null}
                                                                        >
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ),
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        )}

                                        <Box mt={6}>
                                            <CreateNewCoverImage
                                                isLoading={isCoverImagesUploadLoading}
                                                setCoverImageToUpload={setCoverImage}
                                                coverImageToUpload={coverImage}
                                                setIsLoading={setIsCoverImagesUploadLoading}
                                            />
                                            <CreateNewSponsorsGrid
                                                sponsors={sponsors}
                                                setSponsors={setSponsors}
                                                imagesToUpload={sponsorImagesToUpload}
                                                setImagesToUpload={setSponsorImagesToUpload}
                                                isUploadImageLoading={isUploadSponsorImageLoading}
                                                setIsUploadImageLoading={setIsUploadSponsorImageLoading}
                                            />
                                            <CreateNewTnc
                                                isLoading={isLoading}
                                                setIsLoading={setIsLoading}
                                                loadingMessage={loadingMessage}
                                                setLoadingMessage={setLoadingMessage}
                                                setFileToUpload={setFileToUpload}
                                                fileToUpload={fileToUpload}
                                            />
                                        </Box>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={isSubmitting || isDialogLoading}
                                            onClick={() => onClose()}
                                        >
                                            Mégsem
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            disabled={isSubmitting || isDialogLoading}
                                        >
                                            Mentés
                                        </Button>
                                    </DialogActions>
                                </form>
                            )}
                        </Formik>
                    )}
                </Dialog>
            </LocalizationProvider>
        </>
    );
};

export default CreateNewBusinessEventDialog;
